.card-head-content{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
}
.status{
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: rgb(55, 253, 18);
    /* outline: none; */
    border: none;
    backdrop-filter: brightness(0.5);
    box-shadow: 0px 3px 8px #aaa, inset 0px 2px 3px #fff;
    border: solid 1px transparent;  
}

.card-body{
    font-size: 12px;
}

.Header{
    display: flex;
    font-weight: bold;
    flex-wrap: nowrap;
    justify-content: space-between;
    border-bottom: 3px solid black;
    padding: 0;
    margin: 30px 0;

    :last-child{
        display: flex;
        align-items: center;
    }
}

.exportIcon{
    color: #fa5a78;
    font-size: 28px;
    margin-right: 10px;
    filter: drop-shadow(1px 1px 0px lightgray);
}

.table-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    
    >div{
        svg{
            padding:0 5px;
            font-size: 24px;
        }
    }
}

.details-tab-btn{
    color: #fa5a78;
    cursor: pointer;
}

.field-title{
    font-weight: 700;
    padding: 5px 0;
}

Table{
    font-size: 12px;
}
