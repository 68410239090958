// .pad{
//     padding-top: 125px !important;
// }
.pading{
    padding-top: 6rem  !important;
}
.custpad{
    padding-left: 0px  !important;
}
.custom{
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 2px;
    border: 1px solid #d4dbf3;
}
.btn:not(:disabled):not(.disabled) {
    cursor: pointer;
}
.text-9797 {
    color: #979797;
}
.text-5877 {
    color: #f95877;
}
.btn--primary, .btn--vertical::before {
    background-color: #f95877 !important;
}
.login-form {
	width: 100%;
	box-shadow: 0 42px 24px -30px rgba(0, 0, 0, 0.04);
	border-radius: 4px;
	background-color: #ffffff;
	position: relative;
	transition: 0.4s ease-in-out;

	&::before {
		position: absolute;
		// content: "";
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		border-radius: 4px;
		border: 1px solid #d4dbf3;

	}

	&__illustration {
		border-radius: 4px 0 0 4px;
		background-color: #f95877;
	}

	.login-pad {
		padding: 15px;

		@media (min-width: 768px) {
			padding: 35px;
		}

		@media (min-width: 992px) {
			padding: 40px 60px 50px 60px;
		}

		@media (min-width: 1200px) {
			padding: 48px 100px 70px 100px;
		}

		@media (min-width: 1400px) {
			padding: 48px 125px 80px 125px;
		}

		&--extra {
			@media (min-width: 992px) {
				padding: 48px 70px 48px 60px;
			}

			@media (min-width: 1200px) {
				padding: 48px 100px 48px 60px;
			}
		}
	}
}

.form-left-content {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	&__img {
		margin-bottom: 20px;

		@media (max-width: 767px) {
			width: 140px;
		}

		@media (min-width: 768px) {
			margin-bottom: 25px;
		}

		@media (min-width: 992px) {
			margin-bottom: 35px;
		}
	}

	&__head {
		margin-bottom: 15px;

		@media (min-width: 992px) {
			margin-bottom: 25px;
		}
	}

	&__info {
		&:last-of-type {
			margin-bottom: 0;
		}
	}
}

.log-head {
	margin-bottom: 10px;

	@media (min-width: 768px) {
		margin-bottom: 16px;
	}

	@media (min-width: 1200px) {
		margin-bottom: 20px;
	}
}

.login-type-icon {
	text-align: center;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;

	&__Img {
		background-color: #f7f7f7;
		padding: 15px;
		width: 50px;
		height: 50px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin: 0 auto 5px auto;
		border-radius: 4px;
		position: relative;


		
		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			@media (min-width: 768px) {
				width: 75px;
				height: 75px;
				display: flex;
				align-items: center;
				justify-content: center;

			}
		}

		@media (min-width: 1200px) {
			margin: 0 auto 8px auto;
		}

		&::after {
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			border-radius: 8px;
			border: 1px solid #f95877;
			transition: 0.2s ease-in-out;
			opacity: 0;
		}

		img {
			width: 35px;
			height: 35px;
			object-fit: contain;

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				width: 45px;
				height: 38px;
			}
		}
	}

	&__name {
		color: #4a4a4a;
		font-size: 10px;
		font-weight: 500;
		margin: 0 auto 0 auto;
		display: inline-block;
		transition: 0.1s ease-in-out;
	}
}

.login-type {
	margin-bottom: 25px;

	@media (min-width: 768px) {
		margin-bottom: 30px;
	}

	@media (min-width: 992px) {
		margin-bottom: 35px;
	}

	@media (min-width: 1200px) {
		margin-bottom: 45px;
	}
}

.login-type-list {
	display: flex;
	flex-wrap: wrap;

	@media (max-width: 1000px) {
		justify-content: space-between;
	}

	&__item {
		@media (min-width: 768px) {
			margin-right: 25px;
		}

		@media (min-width: 992px) {
			margin-right: 30px;
		}

		@media (min-width: 1200px) {
			margin-right: 40px;
		}

		&:last-child {
			margin-right: 0;
		}
	}
}

.login-container-radio input:checked ~ .login-type-icon .login-type-icon__Img::after {
	opacity: 1;
}

.login-container-radio input:checked ~ .login-type-icon .login-type-icon__Img::after {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		opacity: 0;
	}
}

.login-type-icon__Img {
	

	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		border: 1px solid #f7f7f7;
		text-align: center;
	}
}

.login-container-radio input:checked ~ .login-type-icon .login-type-icon__Img {
	@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
		border-color: #f95877;
	}
}




.login-container-radio input:checked ~ .login-type-icon .login-type-icon__name {
	color: #f95877;
}

.login-container-radio {
	input[type="radio"] {
		display: none;
		opacity: 0;
	}
}

.form-required {
	margin-bottom: 28px;
	position: relative;

	@media (min-width: 768px) {
		margin-bottom: 25px;
	}

	@media (min-width: 992px) {
		margin-bottom: 30px;
	}

	@media (min-width: 1200px) {
		margin-bottom: 35px;
	}

	// &:last-of-type {
	// 	margin-bottom: 0;
	// }

	&--margin {
		margin-bottom: 20px;

		@media (min-width: 768px) {
			margin-bottom: 15px;
		}

		@media (min-width: 992px) {
			margin-bottom: 20px;
		}
	}

}

//Form
.form-fields {
	border-radius: 2px;
	padding: 0 16px;
	position: relative;

	@media (min-width: 768px) {
		padding: 0 16px;
	}

	@media (min-width: 992px) {
		padding: 0 16px;
	}

	.input {
		border: 0;
		outline: 0;
		box-shadow: 0;
		background: 0;
		font-size: 14px;
		font-weight: 400;
		width: 95%;
		padding: 12px 0;
		background-color: 0 !important;

		@media (min-width: 992px) {
			padding: 16px 0;
		}

		&::placeholder {
			color: #979797;
			font-size: 14px;
			font-weight: 400;
		}

		&:focus ~ .form-fields__label {
			top: 0;
			left: 8px;
			transform: translateY(-50%);
			background-color: #ffffff;
			padding: 0 8px;
			color: #f95877;
		}

		&:focus ~ .form-fields__border {
			border-color: #f95877;
		}
	}

	&__label {
		position: absolute;
		top: 50%;
		left: 16px;
		transform: translateY(-50%);
		pointer-events: none;
		transition: 0.3s ease-in-out;
		color: #979797;
		font-size: 14px;
		font-weight: 400;
		z-index: 2;

		&.active {
			top: 0;
			left: 8px;
			transform: translateY(-50%);
			background-color: #ffffff;
			padding: 0 8px;
		}

		&.enabled {
			color: #f95877;
		}
	}

	&__border {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		pointer-events: none;
		border: 1px solid #d6d6d6;
		border-radius: 3px;
		transition: 0.4s ease-in-out;

		&.active {
			border-color: #f95877;
		}
	}

	&--margin {
		margin-bottom: 12px;

		@media (min-width: 768px) {
			margin-bottom: 15px;
		}

		@media (min-width: 992px) {
			margin-bottom: 20px;
		}
	}
}

.see-password {
	opacity: 0.4;

	&.active {
		opacity: 1;
	}
}

.form-data {
	&__forgot {
		a:hover {
			color: #f95877;
		}
	}
}

.form-submit {
	width: 100%;

	&__btn {
		width: 100%;
		opacity: 0.5;
		padding: 12px !important;

		&:disabled {
			opacity: 0.5;
		}

		&:enabled {
			opacity: 1;
		}
	}
}

.login-section {
	width: 100%;

	@media (min-width: 1200px) {
		padding: 0 35px;
	}
}

// Custom Checkbox
.custom-check {
	display: block;
	position: relative;
	padding-left: 22px;
	margin-bottom: 12px;
	cursor: pointer;
	font-size: 12px;
	-webkit-user-select: none;
	-moz-user-select: none;
	-ms-user-select: none;
	user-select: none;
}

.custom-check input {
	position: absolute;
	opacity: 0;
	cursor: pointer;
	height: 0;
	width: 0;
}

// .checkmark {
// 	position: absolute;
// 	top: 0;
// 	left: 0;
// 	height: 15px;
// 	width: 15px;
// 	background-color: 0;
// 	border-radius: 1px;
// 	border: 1px solid #dedede;
// }

// .custom-check input:checked ~ .checkmark {
// 	background-color: #f95877;
// 	border-color: transparent;
// }

// .checkmark::after {
// 	content: "";
// 	position: absolute;
// 	display: none;
// }

// .custom-check input:checked ~ .checkmark::after {
// 	display: block;
// }

// .custom-check .checkmark::after {
// 	left: 5px;
// 	top: 2px;
// 	width: 4px;
// 	height: 9px;
// 	border: solid #ffffff;
// 	border-width: 0 2px 2px 0;
// 	-webkit-transform: rotate(45deg);
// 	-ms-transform: rotate(45deg);
// 	transform: rotate(45deg);
// }


.eror {
	padding-top: 8px;
	width: 100%;

	.eror-list {
		font-size: 10px;
		color: #f95877;
		font-weight: 400;

		@media (min-width: 768px) {
			bottom: -14px;
		}

		@media (min-width: 992px) {
			font-size: 12px;
			bottom: -15px;
		}
	}
}

.login-slider {
	.slick-track {
		display: flex;
		align-items: center;
	}

	&__item {
		display: none;

		@media (max-width: 768px) {
			padding-left: 0 !important;
			padding-right: 0 !important;
		}

		&:first-child {
			display: block;
		}
	}
}

.login-option {
	width: 100%;

	&__head {
		text-align: center;
		padding: 0 25px;
		width: 100%;
		display: block;
		position: relative;

		&::before {
			position: absolute;
			content: "";
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			width: 30%;
			height: 1px;
			background-color: #d4dbf3;
		}

		&::after {
			position: absolute;
			content: "";
			top: 50%;
			right: 0;
			transform: translateY(-50%);
			width: 30%;
			height: 1px;
			background-color: #d4dbf3;
		}
	}
}

.login-option-type {
	justify-content: center;

	&__item {
		margin-right: 32px;
		transition: 0.4s ease-in-out;

		&:last-child {
			margin-right: 0;
		}

		&:hover {
			transform: translateY(-10px);
		}
	}
}

.signUp {
	&__link {
		&:hover {
			color: #f95877;
		}
	}
}


.form-head {
	margin-bottom: 25px;

	@media (min-width: 768px) {
		margin-bottom: 30px;
	}

	@media (min-width: 1200px) {
		margin-bottom: 40px;
	}
}

.validator {
	display: none;
	opacity: 0;
	position: absolute;
	top: -5px;
	right: 0;
	transform: translateY(-100%);
	width: 24px;
	height: 24px;
	border-radius: 50%;

	@media (min-width: 768px) {
		top: 50%;
		right: -36px;
		transform: translateY(-50%);
	}

	&__item {
		position: relative;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		transition: 0.3s ease-in-out;

		.valid-indicator {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			z-index: 1;
		}
	}

	&.invalid {
		display: block;
		opacity: 1;

		.validator__item {
			background-color: #ff3f33;
		}

		.valid-indicator {
			width: 100%;
			height: 100%;
		}
	}

	&.valid {
		display: block;
		opacity: 1;

		.validator__item {
			background-color: #2ec36d;
		}
	}
}
