footer {
  font-family: 'Poppins';
    ul {
        list-style-type: none;
    }
  background-color: #181818;
  padding: 0 !important;
  position: relative;

  @media (min-width: 768px) {
    padding: 60px 0 0 0;
  }

  @media (min-width: 992px) {
    padding: 80px 0 0 0;
  }
  .pad {
    @media (min-width: 1200px) {
      padding: 120px 0 0 0;
    }
  }
}

.foter-links {
  &__head {
    color: #f4f7ff;
    margin-bottom: 10px;
  }

  &--main {
    a {
      &:hover {
        color: #f95877;
      }
    }
  }
}

$main-colorft: #ffffff;
$secondary-color2: #f95877;

.foter-link-list {
  @media (max-width: 767px) {
    margin-bottom: 0;
  }

  &__item {
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    a {
      font-weight: 500;
      color: $main-colorft;
      text-decoration: none;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-image: linear-gradient(
        to right,
        $secondary-color2,
        $secondary-color2 50%,
        $main-colorft 50%
      );
      background-size: 200% 100%;
      background-position: 100%;

      &:hover {
        transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
        background-position: 0%;
      }
    }
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .foter-link-list {
    &__item {
      a {
        color: #949494;
        background-color: none !important;
        background-image: none !important;

        &:hover {
          color: #ffffff;
        }
      }
    }
  }
}

.company-terms {
  padding: 15px 0;
  border-top: 1px solid rgba(148, 148, 148, 0.1);

  @media (min-width: 768px) {
    padding: 20px 0;
  }
}

.footer-social-link {
  &__homelogo {
    width: 130px;

    @media (min-width: 768px) {
      width: 160px;
    }

    @media (min-width: 1200px) {
      width: auto;
      height: auto;
    }
  }
}

.footer-social-links {
  &__item {
    margin-right: 25px;
    transition: 0.2s ease-in-out;
    display: inline-block;

    &:hover {
      transform: scale(1.3);
    }
  }
}
.custompadding {
  padding-left: 0 !important;
}
.alignright{
  margin-right: 1.5rem!important;
}
@media (min-width: 768px){
.ml-md-auto, .mx-md-auto {
    margin-left: auto!important;
}
}
@media (min-width: 768px){
.mr-md-4, .mx-md-4 {
    margin-right: 1.5rem!important;
}
}
@media (min-width: 768px){
  .align{
    text-align: left !important;
  }
}
.pad {
  @media (max-width: 768px) {
    padding-top: 25px;
  }
}

.content-container{
  display: flex;
  flex-wrap: wrap;
  align-content: space-between;
  height: 80vh;
  width: 100%;
}

.userProfileSec{
  border: none;
  font-size: 14px;
  >tbody{
    >td{
      >tr{
        border: none;
        >td{
          border: none;
          padding-right: 20px;
        }
        :first-child{
          font-weight: bold;
        }
      }
    }
  }
}

.programTitle{
  color: #fa5a78;
  font-weight: bold;
  cursor: pointer;
}

.user-programs-list, .program-courses-list{
  font-size: 12px;
}

.enroll-btn{
  font-size: 12px !important;
  font-weight: bold !important;
  padding: 5px !important;
  letter-spacing: 1px !important;
}

.user-page-header{
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
}

.table-header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  
  >div{
      svg{
          padding:0 5px;
          font-size: 24px;
      }
  }
}

