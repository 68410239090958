
main {
	padding-top: 58px;

	@media (min-width: 768px) {
		padding-top: 75px;
	}

	@media (min-width: 992px) {
		padding-top: 75px;
	}

	@media (min-width: 1200px) {
		padding-top: 75px;
	}
}

p {
	font-size: 14px;
	line-height: 22px;
	color: #181818;
	font-weight: 400;

	@media (min-width: 768px) {
		font-size: 16px;
		line-height: 24px;
	}

	@media (min-width: 992px) {
		font-size: 18px;
		line-height: 26px;
	}
}

.sec-pd {
	padding: 25px 0;

	@media (min-width: 768px) {
		padding: 35px 0;
	}

	@media (min-width: 992px) {
		padding: 40px 0;
	}

	@media (min-width: 1200px) {
		padding: 60px 0;
	}

	&:first-of-type {
		@media (min-width: 768px) {
			padding-top: 60px;
		}

		@media (min-width: 992px) {
			padding-top: 80px;
		}

		@media (min-width: 1200px) {
			padding-top: 100px;
		}
	}

	&:last-of-type {
		@media (min-width: 768px) {
			padding-bottom: 60px;
		}

		@media (min-width: 992px) {
			padding-bottom: 80px;
		}

		@media (min-width: 1200px) {
			padding-bottom: 100px;
		}
	}

	&--withBackground {
		padding: 50px 0;

		@media (min-width: 768px) {
			padding: 60px 0;
		}

		@media (min-width: 992px) {
			padding: 80px 0;
		}

		@media (min-width: 1200px) {
			padding: 100px 0;
		}
	}

	&--top {
		padding-top: 50px;

		@media (min-width: 768px) {
			padding-top: 60px;
		}

		@media (min-width: 992px) {
			padding-top: 80px;
		}

		@media (min-width: 1200px) {
			padding-top: 100px;
		}
	}

	&--eBttm {
		padding-bottom: 280px;
		margin-bottom: -120px;
		
		@media(max-width: 767px) {
			padding-bottom: 80px;
    		margin-bottom: -30px;
		}
	}
}

.updates {
	-ms-writing-mode: tb-rl; /* old syntax, IE */
	-webkit-writing-mode: vertical-rl;
	writing-mode: vertical-rl;
	transform: rotate(180deg);
	max-height: 100vh;
	text-align: right;
	position: fixed;
	bottom: 0;
	right: 0;
	display: flex;
	align-items: flex-end;
	z-index: 99;
	pointer-events: all;

	@media (min-width: 992px) {
		bottom: auto;
		top: 35%;
	}

	@media (min-width: 1200px) {
		top: 40%;
	}

	&__events {
		.btn {
			min-width: auto !important;
			transform: translateX(-10%);
			transition: 0.5s ease-in-out;
			padding: 12px !important;
		}

		&:hover {
			.btn {
				transform: translateX(0);
				color: #ffffff !important;
			}
		}
	}

	.whatsapp {
		width: 48px;
		height: 48px;
		position: relative;
		margin-bottom: 16px;
		pointer-events: all;
		cursor: pointer;
		transform: translateX(-10%);
		transition: 0.5s ease-in-out;

		&:hover {
			transform: translateX(0);
		}

		&__img {
			position: absolute;
			z-index: 9;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(-175deg);

			@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
				transform: translate(-50%, 50%) rotate(-175deg);
			}
		}

		&::before {
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			border-radius: 40px 0 0 40px;
			background-color: #4caf50;
			top: 0;
			left: 0;
			transform: rotate(180deg);
			z-index: -1;
		}

		&--2 {
			&::before {
				background-color: #ffffff;
				border: 1px solid #d4dbf3;
				border-right: 0;
			}
		}
	}
}

.chat {
	position: fixed;
	bottom: 15px;
	right: 15px;
	cursor: pointer;
	z-index: 99;

	@media (min-width: 768px) {
		bottom: 5%;
		right: 5%;
	}

	&__icon {
		width: 40px;
		height: 40px;
		box-shadow: 0 25px 30px -10px rgba(22, 42, 97, 0.1);
		border: 1px solid #d4dbf3;
		background-color: #ffffff;
		border-radius: 50%;
		position: relative;

		@media (min-width: 768px) {
			width: 50px;
			height: 50px;
		}

		@media (min-width: 1200px) {
			width: 65px;
			height: 65px;
		}

		img {
			position: absolute;
			z-index: 9;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%) rotate(0deg);
			width: 20px;

			@media (min-width: 768px) {
				width: 30px;
			}

			@media (min-width: 992px) {
				width: 40px;
			}

			@media (min-width: 1200px) {
				width: auto;
			}
		}
	}
}

input {
	&::placeholder {
		transition: 0.2s ease-in-out;
	}

	&:focus {
		&::placeholder {
			opacity: 0;
		}
	}
}

.animate-line {
	position: relative;

	&::after {
		position: absolute;
		content: "";
		bottom: -2px;
		left: 0;
		height: 1px;
		width: 0;
		background-color: #f95877;
		transition: 0.3s ease-in-out;
	}

	&:hover {
		color: #f95877 !important;

		&::after {
			width: 100%;
		}
	}
}

a {
	&:hover {
		color: inherit;
	}
}

.vertical-tab {
	flex-direction: column;

	.nav-link {
		background: 0;
		padding: 10px;
		color: #000000;
		position: relative;
		z-index: 3;

		@media (min-width: 768px) {
			padding: 10px 25px 10px 25px;
		}

		@media (min-width: 1200px) {
			padding: 15px 30px 15px 30px;
		}

		&::before {
			@media (min-width: 768px) {
				position: absolute;
				content: "";
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				opacity: 0;
				border-radius: 2px;
				background-color: #f95877;
				transition: 0.3s ease-in-out;
				z-index: -1;
			}
		}

		&.active {
			background: 0;
			color: #000000;

			@media (min-width: 768px) {
				color: #ffffff;
			}

			&::before {
				@media (min-width: 768px) {
					opacity: 1;
				}
			}

			&:hover {
				@media (min-width: 768px) {
					color: #ffffff;
				}
			}
		}

		&:hover {
			color: #f95877;
		}
	}

	&--contact {
		position: relative;

		.slick-current {
			.nav-link {
				background: 0 !important;
				color: #f95877 !important;

				&::after {
					width: 100%;
				}

				&.active {
					color: #f95877 !important;

					&::after {
						width: 100%;
					}
				}
			}
		}

		&::before {
			position: absolute;
			content: "";
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			background-color: #f6f8ff;
			z-index: 1;
		}
	}
}


.responsive-list-tabs {
	position: relative;
	.nav-link {
		background: 0 !important;
		color: #f95877;

		&::after {
			width: 100%;
		}

		&.active {
			color: #f95877;

			&::after {
				width: 100%;
			}
		}
	}

	&::before {
		position: absolute;
		content: "";
		bottom: 0;
		left: 0;
		width: 100%;
		height: 1px;
		border-radius: 2px;
		background-color: #d4dbf3;
		transition: 0.3s ease-in-out;
		z-index: 1;
	}
}


.slick-slider {
	.slick-current {
		.nav-link {
			background: 0 !important;
			color: #f95877 !important;

			&::after {
				width: 100%;
			}

			&.active {
				color: #f95877 !important;

				&::after {
					width: 100% !important;
				}
			}
		}
	}

	.nav-link {
		background: 0;
		color: #000000;

		&::after {
			width: 100%;
		}

		&.active {
			color: #000000;

			&::after {
				width: 0;
			}
		}
	}
}


.custom-radios {
	.parsley-errors-list {
		bottom: -22px;
		left: 20px;
	}
}

.parsley-errors-list {
	position: absolute;
	bottom: -75%;
	left: 0;
	min-width: 300px;

	@media (min-width: 768px) {
		bottom: -67%;
	}

	li {
		color: #f95877;
		font-size: 12px;

		@media (min-width: 768px) {
			font-size: 10px;
		}

		@media (min-width: 992px) {
			font-size: 12px;
		}
	}
}

.form-input {
	position: relative;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

/* Firefox */
input[type=number] {
	-moz-appearance: textfield;
}


.input-check {
	height: 40px;
	padding: 10px 0;
	width: 100%;
	opacity: 0;

	@media (min-width: 768px) {
		padding: 16px 0;
	}

	@media (min-width: 992px) {
		height: 48px;
	}
}

.hidden-input {
	display: none;
	opacity: 0;
}

.input-rad {
	display: none;
	opacity: 0;
}

.link {
	position: relative;
	display: inline-block;
	

	&--clr {
		color: #fc5675;
	}

	&::before {
		position: absolute;
		content: "";
		bottom: 0;
		left: 0;
		width: 0;
		height: 1px;
		background-color: #f95877;
		opacity: 0;
		transition: 0.4s ease-in-out;
	}

	&:hover {
		color: #f95877;

		&::before {
			width: 100%;
			opacity: 1;
		}
	}
}

#map {
	height: 100%;
	width: 100%;
}

.map {
	width: 100%;
	height: 250px;

	@media (min-width: 768px) {
		height: 300px;
	}

	@media (min-width: 992px) {
		position: absolute;
		height: 100%;
		width: 50%;
		top: 0;
		right: 0;
	}
}

.disbale {
	pointer-events: none;
}

textarea {
	resize: none;
}

.sub-tab-list {
	background-color: #f6f8ff;

	@media (max-width: 767px) {
		display: none;
		opacity: 0;
	}

	&::before {
		content: none !important;
		display: none;
	}

	&__item {
		color: #000000;
		font-size: 14px;
		font-weight: 600;
		padding: 6px 8px;
		transition: 0.2s ease-in-out;
		cursor: pointer;

		@media (min-width: 768px) {
			padding: 10px 25px;
			font-size: 14px;
			line-height: 24px;
		}

		@media (min-width: 992px) {
			font-size: 16px;
			line-height: 26px;
		}

		@media (min-width: 1200px) {
			padding: 15px 30px;
			font-size: 18px;
			line-height: 28px;
		}

		&.active {
			background-color: #fa5a78;
			color: #ffffff;

			&:hover {
				color: #ffffff !important;
			}
		}

		&:hover {
			color: #fa5a78;
		}
	}
}

.sub-tab-content-list {
	&__item {
		display: none;
		opacity: 0;
		transition: 0.4s ease-in-out;

		&.active {
			display: block;
			opacity: 1;
		}
	}
}

.tab-pad-left {
	@media (min-width: 768px) {
		padding-left: 15px;
	}

	@media (min-width: 992px) {
		padding-left: 30px;
	}

	@media (min-width: 1200px) {
		padding-left: 10px;
	}
}

.custom-arrows {
	.slick-list {
		padding: 0 8px !important;
	}

	.slick-next {
		position: absolute;
		top: 0;
		right: 20px;
		outline: 0;
		box-shadow: none;
		border: 1px solid #f95877;
		background-image: url("../images/arrowNext.png");
		background-position: center;
		background-repeat: no-repeat;
		background-color: inherit !important;
		font-size: 0;
		height: 25px;
		width: 25px;

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			background-color: #ffffff;
		}

		@media (min-width: 768px) {
			right: 0;
			top: -50px;
		}

		&.slick-disabled {
			border-color: #979797 !important;
			transform: rotate(180deg);
			background-image: url("../images/arrowPrev.png");
		}
	}

	.slick-prev {
		position: absolute;
		top: 0;
		right: 60px;
		outline: 0;
		box-shadow: none;
		border: 1px solid #f95877;
		background-image: url("../images/arrowNext.png");
		background-position: center;
		background-repeat: no-repeat;
		background-color: inherit !important;
		font-size: 0;
		height: 25px;
		width: 25px;
		transform: rotate(180deg);

		@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
			background-color: #ffffff;
		}


		@media (min-width: 768px) {
			right: 40px;
			top: -50px;
		}

		&.slick-disabled {
			border-color: #979797 !important;
			transform: rotate(0deg);
			background-image: url("../images/arrowPrev.png");
		}
	}
}

.tab-content {
	&--student {
		padding-top: 20px;

		@media (min-width: 768px) {
			padding-top: 65px;
		}
	}
}

.Blueline-border {
	border-radius: 12px;
	border: 1px solid #d4dbf3;
	background-color: #ffffff;
	padding: 15px;

	@media (min-width: 768px) {
		padding: 25px;
	}

	@media (min-width: 992px) {
		padding: 30px;
	}

	@media (min-width: 1200px) {
		padding: 40px;
	}
}

.pad-div {
	padding: 15px;

	@media (min-width: 768px) {
		padding: 25px;
	}

	@media (min-width: 992px) {
		padding: 30px;
	}

	@media (min-width: 1200px) {
		padding: 40px;
	}
}

.img-div {
	width: 100%;
	height: 233px;

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		border-bottom-left-radius: 12px;
		border-bottom-right-radius: 12px;
	}
}

.image {
	margin-bottom: 10px;

	.img {
		border-radius: 12px;
		width: 100%;
		height: auto;
	}
}

.radius-12 {
	border-radius: 12px;
}


.img-responsive {
	width: 100%;
	height: 210px;

	@media (min-width: 992px) {
		height: 180px;
	}

	img {
		width: 100%;
		height: 100%;
		object-fit: cover;

		@media (min-width: 992px) {
			width: auto;
		}
	}
}

.bg-blueGrad {
	background-image: linear-gradient(210deg, #23cbff 0%, #0facdc 100%);
}

.bg-redGred {
	background-color: #e25b3d;
}

.bg-yellowGrad {
	background-color: #f2ca2b;
}

.bg-greenGrad {
	background-color: #a4c132;
}


.bg-darkBlueGrad {
	background-color: #478fb7;
}

.random-divs {
	&__item {
		margin-bottom: 25px;

		@media (min-width: 768px) {
			margin-bottom: 50px;
		}

		@media (min-width: 992px) {
			margin-bottom: 90px;
		}

		@media (min-width: 1200px) {
			margin-bottom: 100px;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}
}

.radius-0 {
	border-radius: 0 !important;
}

.radius-btm-0 {
	border-bottom-left-radius: 0 !important;
	border-bottom-right-radius: 0 !important;
}

.points-color {
	&__item {
		position: relative;
		padding-left: 15px;

		@media (min-width: 768px) {
			padding-left: 30px;
		}

		@media (min-width: 992px) {
			padding-left: 40px;
		}

		&::before {
			position: absolute;
			content: "";
			top: 8px;
			left: 0;
			width: 10px;
			height: 10px;
			border-radius: 50%;
			background-size: cover;
			background-position: center;
			background-repeat: no-repeat;
			z-index: 1;

			@media (min-width: 768px) {
				width: 12px;
				height: 12px;
				top: 9px;
			}

			@media (min-width: 992px) {
				width: 15px;
				height: 15px;
				top: 10px;
			}
		}

		&:nth-child(3n+3) {
			&::before {
				// background-image: url("../img/indigo.png");
			}
		}

		&:nth-child(3n+2) {
			&::before {
				// background-image: url("../img/yellow.png");
			}
		}

		&:nth-child(3n+1) {
			&::before {
				// background-image: url("../img/red.png");
			}
		}
	}
}


.h-100vh {
	@media (min-width: 768px) {
		min-height: 100vh;
	}
}

.sec-height {
	&-800 {
		@media (min-width: 768px) {
			min-height: 800px;
		}
	}
}


.update-event {
	position: fixed;
	right: -100%;
	top: 80px;
	box-shadow: -10px 2px 14px rgba(0, 0, 0, 0.06);
	background-color: #ffffff;
	padding: 25px 30px;
	width: 100%;
	height: 100vh;
	transition: 0.6s ease-in-out;
	padding-bottom: 200px;
	z-index: 99;

	@media (max-width: 767px) {
		top: 58px !important;
	}

	&--dashboard {
		top: 58px !important;

		@media (min-width: 768px) {
			top: 66px !important
		}

		@media (min-width: 1200px) {
			top: 78px !important
		}
	
		
	}

	@media (min-width: 768px) {
		width: 50%;
	}

	@media (min-width: 992px) {
		width: 43%;
	}

	@media (min-width: 1200px) {
		width: 36%;
	}

	@media (min-width: 1400px) {
		width: 33%;
	}


	&.active {
		right: 0;
	}

	&__head {
		margin-bottom: 25px;
	}
}

.close-events {
	position: relative;
	width: 20px;
	height: 20px;
	cursor: pointer;

	&::after {
		position: absolute;
		content: "";
		top: 0;
		left: 8px;
		width: 2px;
		height: 100%;
		transform: rotate(45deg);
		background-color: #242424;
		z-index: 9;
	}

	&::before {
		position: absolute;
		content: "";
		top: 0;
		left: 8px;
		width: 2px;
		height: 100%;
		transform: rotate(-45deg);
		background-color: #242424;
		z-index: 9;
	}

	&:hover {
		&::after,
		&::before {
			background-color: #f95877;
		}
	}
}

.event-disc {
	margin-top: 25px;

	@media (min-width: 992px) {
		margin-top: 30px;
	}
}

.event-list {
	&__item {
		padding: 15px 0;
		border-top: 1px solid #d4dbf3;
	}
}

.img-spin2 {
	width: 100%;
	height: 80%;
}


.section-spin {
	overflow-x: hidden;
}

.regular-list {
	&__item {
		margin-bottom: 15px;

		&:last-child {
			margin-bottom: 0;
		}

		&:nth-last-child(2n) {

			@media (min-width: 768px) {
				margin-bottom: 0;
			}
			
		}
	}
}

.common-card {
	box-shadow: 0 25px 30px -10px rgba(22, 42, 97, 0.1);
	border-radius: 8px;
	border: 1px solid #d4dbf3;
	background-color: #ffffff;
	padding: 15px;

	@media (min-width: 768px) {
		padding: 25px 20px;
	}

	@media (min-width: 992px) {
		padding: 40px 35px;
	}

	@media (min-width: 1200px) {
		padding: 48px 40px;
	}

	&--mission {
		position: relative;

		&::before {
			position: absolute;
			content: "";
			width: 95px;
			height: 60px;
			top: -20px;
			right: -15px;
			// background-image: url(../img/home/dots.svg);
			background-repeat: no-repeat;
			z-index: -1;

			@media (min-width: 768px) {
				width: 155px;
				height: 155px;
				top: -30px;
				right: -15px;
			}

			@media (min-width: 1200px) {
				width: 153px;
				height: 180px;
				top: -50px;
				right: -60px;
			}
		}
	}
}
.blockquoteLeft {
	position: absolute;
	z-index: 9;
	max-width: 100%;
	height: auto;

	@media (max-width: 767px) {
		transform: translate(14px, 10px) rotate(180deg);
	}


	@media (min-width: 768px) {
		transform: translate(10px, 10px) rotate(180deg);
	}

}

.blockquoteRight {
	position: absolute;
	z-index: 9;
	max-width: 100%;
	height: auto;

	@media (max-width: 767px) {
		transform: translate(-33px, -3px);
	}

	
	@media (min-width: 768px) {
		transform: translate(-30px, 0);
	}
	
}

.img-contain {
	width: 25px;
	height: 25px;
	object-fit: contain;

	@media (min-width: 768px) {
		width: 30px;
		height: 30px;
	}

	@media (min-width: 1200px) {
		width: 35px;
		height: 35px;
	}
}

.random-divs {
	.careers-list {
		&__item {
			@media (min-width: 992px) {
				width: calc(50% - 13px);
				margin-bottom: 26px;
			}
		}
	}
}

.bg-fb {
	background-color: #39579a;
}


.scroll-list {
	.ps__rail-x {
		display: none !important;
	}

	@media (min-width: 768px) {
		padding-right: 25px;
	}

	@media (min-width: 992px) {
		padding-right: 35px;
	}
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
	.ie-sticky {
		position: fixed !important;
	}

	.sub-tab-list {
		&.ie-sticky {
			@media (min-width: 768px) {
				max-width: 210px;
			}

			@media (min-width: 1200px) {
				max-width: 255px;
			}
		}
	}
}

.sub-tab-list {
	&.ie-sticky {
		@media (min-width: 768px) {
			max-height: calc(100vh - 140px);
		}
	}
}

.radius12 {
	border-radius: 12px;
}

.company-banner-img {
	transform: translateY(-25px);
	margin-bottom: 0;

	@media (min-width: 768px) {
		display: none;
		opacity: 0;
		visibility: hidden;
	}
}