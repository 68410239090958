/* Slider */

.slick-slider {
    position: relative;
    display: block;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
    // .slick-track {
    //     -webkit-transform: translate3d(0, 0, 0);
    //     -moz-transform: translate3d(0, 0, 0);
    //     -ms-transform: translate3d(0, 0, 0);
    //     -o-transform: translate3d(0, 0, 0);
    //     transform: translate3d(0, 0, 0);
    // }
    // .slick-list {
    //     -webkit-transform: translate3d(0, 0, 0);
    //     -moz-transform: translate3d(0, 0, 0);
    //     -ms-transform: translate3d(0, 0, 0);
    //     -o-transform: translate3d(0, 0, 0);
    //     transform: translate3d(0, 0, 0);
    // }
}

.slick-list, .slick-slider {
    z-index: 1;
}

.slick-list {
    position: relative;
    display: block;
    overflow: hidden;
    margin: 0;
    padding: 0;
    &:focus {
        outline: none;
    }
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}

.slick-track {
    position: relative;
    top: 0;
    left: 0;
    display: block;
    &:before {
        display: table;
        content: '';
    }
    &:after {
        display: table;
        content: '';
        clear: both;
    }
}

.slick-loading {
    .slick-track {
        visibility: hidden;
    }
    .slick-slide {
        visibility: hidden;
    }
}

.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
    img {
        display: block;
    }
}

[dir='rtl'] {
    .slick-slide {
        float: right;
    }
}

.slick-slide.slick-loading {
    img {
        display: none;
    }
}

.slick-slide.dragging {
    img {
        pointer-events: none;
    }
}

.slick-initialized {
    .slick-slide {
        display: block;
    }
}

.slick-vertical {
    .slick-slide {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}
