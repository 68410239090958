// Professionals
.tab-space {
	width: 100%;

	@media (min-width: 768px) {
		height: 100%;
	}

	&__left {
		background-color: #fee7eb;
		padding: 20px 15px;

		@media (min-width: 768px) {
			padding: 25px 20px 140px 20px;
			border-top-left-radius: 8px;
			border-bottom-left-radius: 8px;
			height: 100%;
		}

		@media (min-width: 992px) {
			padding: 35px 30px 140px 30px;
		}

		@media (min-width: 1200px) {
			padding: 50px 40px 130px 40px;
		}
	}

	&__right {
		background-color: #ffffff;
		padding: 20px 15px;

		@media (min-width: 768px) {
			padding: 25px 20px;
			border-top-right-radius: 8px;
			border-bottom-right-radius: 8px;
			height: 100%;
		}

		@media (min-width: 992px) {
			padding: 35px 30px;
		}

		@media (min-width: 1200px) {
			padding: 50px 40px;
		}
	}
}

.tab-bg {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: #f6f8ff;
	z-index: -1;

	@media (min-width: 768px) {
		height: 85%;
	}

	@media (min-width: 1200px) {
		height: 80%;
	}
}

.div-titile {
	margin-bottom: 0;
	font-size: 14px;
	line-height: 24px;

	@media (min-width: 768px) {
		margin-bottom: 10px;
		font-size: 16px;
		line-height: 26px;
	}

	@media (min-width: 992px) {
		font-size: 18px;
		line-height: 28px;
	}

	@media (min-width: 1200px) {
		font-size: 24px;
		line-height: 34px;
	}
}

.div-head {
	margin-bottom: 10px;
	font-size: 20px;
	line-height: 30px;

	@media (min-width: 768px) {
		margin-bottom: 15px;
		font-size: 22px;
		line-height: 32px;
	}

	@media (min-width: 992px) {
		margin-bottom: 20px;
		font-size: 26px;
		line-height: 36px;
	}

	@media (min-width: 1200px) {
		margin-bottom: 25px;
		font-size: 32px;
		line-height: 42px;
	}
}

.div-info {
	margin-bottom: 4px;

	@media (min-width: 768px) {
		margin-bottom: 10px;
	}
}

.div-btn {
	margin-top: 20px;

	@media (min-width: 768px) {
		margin-top: 30px;
	}

	@media (min-width: 992px) {
		margin-top: 35px;
		margin-bottom: 50px;
	}

	@media (min-width: 1200px) {
		margin-top: 45px;
		margin-bottom: 80px;
	}
}

.programs-type {
	width: 50%;

	.careers-list {
		&__item {
			width: calc(100% - 5px);
		}
	}

	&--right {
		.careers-list {
			&__item {
				margin-left: auto;
			}
		}

		.programs-type__head {
			margin-left: 5px;
		}
	}
}

.programs {
	width: 100%;
	height: 100%;

	&__Img {
		width: 100%;
		height: auto;

		@media (min-width: 992px) {
			width: 100%;
			height: 100%;
			object-fit: cover;
		}
	}
}
