$screen-xs: 479.9px;
$screen-sm: 575.9px;
$screen-md: 767.9px;
$screen-lg: 991.9px;
$screen-xl: 1199.9px;
$screen-vlg: 1539.9px;

.fnt {
	&-12 {
		font-size: 12px;
		line-height: 22px;
	}

	&-14 {
		font-size: 14px;
		line-height: 24px;
	}

	&-15 {
		font-size: 14px;
		line-height: 24px;

		@media (min-width: 768px) {
			font-size: 15px;
			line-height: 25px;
		}
	}


	&-16 {
		font-size: 14px;
		line-height: 24px;

		@media (min-width: 768px) {
			font-size: 16px;
			line-height: 26px;
		}
	}

	&-18 {
		font-size: 14px;
		line-height: 24px;

		@media (min-width: 768px) {
			font-size: 16px;
			line-height: 26px;
		}

		@media (min-width: 992px) {
			font-size: 18px;
			line-height: 28px;
		}
	}

	&-20 {
		font-size: 18px;
		line-height: 28px;

		@media (min-width: 768px) {
			font-size: 20px;
			line-height: 30px;
		}
	}

	&-24 {
		font-size: 18px;
		line-height: 28px;

		@media (min-width: 768px) {
			font-size: 22px;
			line-height: 32px;
		}

		@media (min-width: 992px) {
			font-size: 24px;
			line-height: 34px;
		}
	}

	&-32 {
		font-size: 20px;
		line-height: 30px;

		@media (min-width: 768px) {
			font-size: 26px;
			line-height: 36px;
		}

		@media (min-width: 992px) {
			font-size: 32px;
			line-height: 42px;
		}
	}

	&-42 {
		font-size: 25px;
		line-height: 35px;

		@media (min-width: 768px) {
			font-size: 32px;
			line-height: 42px;
		}

		@media (min-width: 992px) {
			font-size: 42px;
			line-height: 52px;
		}
	}

	&-40 {
		font-size: 25px;
		line-height: 35px;

		@media (min-width: 768px) {
			font-size: 32px;
			line-height: 42px;
		}

		@media (min-width: 992px) {
			font-size: 42px;
			line-height: 52px;
		}

		@media (min-width: 1200px) {
			font-size: 48px;
			line-height: 58px;
		}
	}

	&-48 {
		font-size: 25px;
		line-height: 35px;

		@media (min-width: 768px) {
			font-size: 32px;
			line-height: 42px;
		}

		@media (min-width: 992px) {
			font-size: 42px;
			line-height: 52px;
		}

		@media (min-width: 1200px) {
			font-size: 48px;
			line-height: 58px;
		}
	}
}

.fw-100 {
	font-weight: 100;
}

.fw-300 {
	font-weight: 300;
}

.fw-400 {
	font-weight: 400;
}

.fw-500 {
	font-weight: 500;
}

.fw-600 {
	font-weight: 600;
}

.fw-700 {
	font-weight: 700;
}


.text {
	&-00 {
		color: #000000;
	}
}
