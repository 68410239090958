header {
  border-bottom: 1px solid #cbcfed;
}
.contentpadding {
  padding-left: 0px;
}

// Side nav
.sidenav-dashboard {
  background-color: #f6f8ff;
  height: 100vh;
  position: fixed;
  left: -100%;
  transition: 0.6s ease-in-out;
  z-index: 997;

  @media (max-width: 767px) {
    // top: 0;

    width: 75%;
  }

  @media (min-width: 768px) {
    left: 0;
  }

  &.active {
    @media (max-width: 767px) {
      left: 0;
    }
  }
}

.sidenav-list {
  display: inline-block;
  width: 100%;
  // height: 100%;
  padding: 0 0 25px 0;

  @media (min-width: 768px) {
    padding: 0px 0 0px 0;
  }

  &__item {
    .list-head {
      padding: 10px;
      position: relative;
      cursor: pointer;

      &__title {
        position: relative;
        z-index: 2;
      }

      &::after {
        position: absolute;
        content: "";
        top: 18px;
        right: 0;
        transition: 0.3s ease-in-out;
        z-index: 1;
        box-sizing: border-box;
        height: 7px;
        width: 7px;
        border-style: solid;
        border-color: #000000;
        border-width: 0 1px 1px 0;
        transform: rotate(45deg);

        @media (max-width: 767px) {
          display: none;
          border-color: #ffffff;
          border-width: 0 2px 2px 0;
          top: 10px;
        }
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        left: -15px;
        width: calc(100% + 15px);
        height: 100%;
        background-color: #fa5a78;
        opacity: 0;
        transition: 0.2s ease-in-out;
      }
    }

    .list-dropdown {
      padding: 10px 10px 10px 20px;
      display: none;

      &__link {
        display: block;
        color: #5d5d5d;
        font-weight: 500;
        margin-bottom: 5px;
        transition: 0.2s ease-in-out;

        &:last-child {
          margin-bottom: 0;
        }

        &:hover {
          color: #fa5a78;
        }
      }
    }

    &.active {
      .list-head {
        &::after {
          border-color: #ffffff;
          transform: rotate(-135deg);
        }

        &::before {
          opacity: 1;
        }

        &__title {
          color: #ffffff;
        }
      }
    }
  }
}

.dashboard-content {
  padding-top: 25px;

  @media (min-width: 768px) {
    padding: 30px 0 0 30px;
  }
}

.dashboard-content {
  &__profileName {
    margin-bottom: 20px;
  }

  &__banner {
    min-height: 260px;
    border-radius: 8px;
    background-image: linear-gradient(183deg, #c1cfff 0%, #b1c7ff 100%);
    position: relative;
    margin-bottom: 20px;
    padding: 25px 15px 40px 15px;

    @media (min-width: 768px) {
      padding: 30px 30px 50px 30px;
      min-height: 230px;
      margin-bottom: 25px;
    }

    @media (min-width: 992px) {
      padding: 50px 50px 90px 50px;
    }

    @media (min-width: 1200px) {
      padding: 90px 90px 130px 90px;
      min-height: 315px;
      margin-bottom: 30px;
    }

    .img {
      display: none;
      width: 50%;
      height: 100%;
      object-fit: cover;
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

.dashboard-card-list {
  &__item {
    width: 100%;
    margin-bottom: 15px;

    &:last-child {
      @media (max-width: 767px) {
        margin-bottom: 0;
      }
    }

    @media (min-width: 768px) {
      width: 49%;
      margin-right: 2%;
      margin-bottom: 2%;
    }

    @media (min-width: 992px) {
      width: 32%;
      margin-right: 2%;
      margin-bottom: 2%;
    }

    @media (min-width: 1200px) {
      width: 23.5%;
      margin-right: 2%;
      margin-bottom: 2%;
    }

    &:nth-child(even) {
      @media (min-width: 768px) and (max-width: 991px) {
        margin-right: 0;
      }
    }

    &:nth-child(3n) {
      @media (min-width: 992px) and (max-width: 1199px) {
        margin-right: 0;
      }
    }

    &:nth-child(4n) {
      @media (min-width: 1200px) {
        margin-right: 0;
      }
    }

    .Card {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      position: relative;
      overflow: hidden;
      display: block;
      background-color: #fc5675;
      border: 0;

      &:hover {
        .Card__text {
          opacity: 1;
          transform: rotateY(0deg);
        }

        .Card__body {
          opacity: 0.7;
          transform: rotateY(360deg);
        }
      }

      &__text {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #181818;
        border-radius: 8px;
        padding: 25px 15px 35px 15px;
        transform: rotateY(-180deg);
        transition: 0.4s ease-in-out;
        opacity: 0;
        pointer-events: none;

        @media (min-width: 768px) {
          padding: 35px 15px 45px 15px;
        }

        @media (min-width: 1200px) {
          padding: 45px 16px 80px 16px;
        }

        p {
          position: relative;
          z-index: 9;
        }
      }

      &__body {
        width: 100%;
        transform: rotateY(0deg);
        transition: 0.4s ease-in-out;
        opacity: 1;
      }

      // &:hover {
      // 	.Card__footer {
      // 		bottom: 0;
      // 	}
      // }

      &__footer {
        padding: 15px;
        background-color: #000000;
        border-bottom-right-radius: 8px;
        border-bottom-left-radius: 8px;
        width: 100%;
        position: absolute;
        bottom: 0;
        transition: 0.4s ease-in-out;
        z-index: 1;
      }

      .ftr-link {
        display: inline-block;
        margin-bottom: 0;
        position: relative;
        padding-right: 15px;

        &::after {
          position: absolute;
          content: "";
          top: 10px;
          right: 0;
          transition: 0.3s ease-in-out;
          z-index: 1;
          box-sizing: border-box;
          height: 7px;
          width: 7px;
          border-style: solid;
          border-color: #ffffff;
          border-width: 0 1px 1px 0;
          transform: rotate(-45deg);
          z-index: 3;

          // @media (max-width: 767px) {
          // 	display: none;
          // 	border-color: #ffffff;
          // 	border-width: 0 2px 2px 0;
          // 	top: 10px;
          // }
        }
      }
    }

    .card-img {
      img {
        width: 100%;
        height: auto;
        object-fit: cover;
      }
      background-color: #fa5a78;
    }
  }
}

.dashboard-baner-bg {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 0;
  width: 70%;
  height: 100%;
}

.dashboard-baner-text {
  z-index: 9;
}

.dashboard-sidenavBtn {
  width: 20px;
  margin: 0 20px 20px 0;

  &__item {
    width: 20px;
    height: 2px;
    background-color: #000000;
    margin-bottom: 3px;
    display: block;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.js-close-dashboardNav {
  color: #fc5675;
  padding: 15px 0;
}

.dashboard-head-links {
  @media (max-width: 991px) {
    position: fixed;
    width: 40%;
    right: -100%;
    top: 0;
    height: 100%;
    padding: 50px 20px 20px 20px;
    box-shadow: -10px 2px 14px rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    z-index: 997;
    display: block;
    transition: 0.3s ease-in-out;
  }
  @media (max-width: 767px) {
    width: 100% !important;
  }

  &.open {
    @media (max-width: 991px) {
      right: 0;
    }
  }

  &__item {
    @media (max-width: 991px) {
      display: block;
      margin-bottom: 35px;
      width: 100%;
    }

    a {
      @media (max-width: 991px) {
        display: block !important;
        font-size: 18px;
        line-height: 28px;
        font-weight: 700;
        color: #000000 !important;
        // -webkit-text-fill-color: #fc5675 !important;
      }
    }
  }
}

// for navbaar dropdown
.active {
  display: block !important;
}

.disabledropdown {
  &::after {
    display: none;
  }
}

//notification-icon

.notification-icon {
  width: 21px;
  height: 21px;
}
.badge1 {
  background-color: red;
  position: relative;
  border-radius: 50%;
  top: -7px;
  width: 18px;
  left: -5px;
  text-align: center;
  height: 17px;
  font-size: 12px;
  color: #ffffff;
}

.pagination{
  justify-content: center;
}

.pagination>li{
padding: 2px 5px;
}

.coming-soon {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.centerAlign{
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}


.ic-icon-svg {
  width: 35px;
  display: inline-block;
  vertical-align: middle;
  position: relative;
}
.icon-div{
  padding: 0px 25px;
  text-align: center;
}
@media (max-width:768px){
  .sidenav-dashboard{
    width: 260px;
  }
  .tab-pad{
    padding-left: 230px;
  }
  .header-search{
    position: absolute;
    right: 60px;
  }
  .course-pad{
    padding-left: 275px !important;
  }
  .remove-course-pading{
    padding-left: 0 !important;
  }
  .text2424{
    top: -18px;
  }
}
// .pagination a:hover:not(.active) {background-color: #ddd;}


.pagination a {
  color: black;
  float: left;
  padding: 8px 16px;
  text-decoration: none;
  border: 1px solid #ddd;
}

.pagination .activeElement a{
  background-color: #fa5a78;
  color: white;
  border: 1px solid #fa5a78;
}

// .pagination a:hover:not(.active) {background-color: #ddd;}

// .pagination a:first-child {
//   border-top-left-radius: 5px;
//   border-bottom-left-radius: 5px;
// }

// .pagination a:last-child {
//   border-top-right-radius: 5px;
//   border-bottom-right-radius: 5px;
// }

.menu-icon{
  font-size: 35px;
  position: relative;
}