.btn {
  background-color: #fa5a78;
  color: #ffffff;
  background: none;
  font-size: 14px;
  font-weight: 500;
  min-width: 70px;
  z-index: 2;

  @media (min-width: 768px) {
    font-size: 13px;
  }

  @media (min-width: 992px) {
    padding: 12px 18px;
    font-size: 16px;
    min-width: 100px;
  }

  &--skew {
    position: relative;
    transition: 0.3s ease-in-out;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 55%;
      height: 100%;
      background-color: #f95877;
      transform: skew(-8deg);
      border-radius: 4px;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      transition: 0.4s ease-in-out;
      z-index: -1;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 55%;
      height: 100%;
      background-color: #f95877;
      transform: skew(8deg);
      border-radius: 4px;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      transition: 0.4s ease-in-out;
      z-index: -1;
    }

    &:hover {
      color: #ffffff;

      &::after {
        transform: skew(8deg);
      }

      &::before {
        transform: skew(-8deg);
      }
    }
  }

  &--skewBrd {
    position: relative;
    transition: 0.3s ease-in-out;
    color: #f95877;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .img-brd {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      color: #ffffff;

      .img-brd {
        display: none;
      }

      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 55%;
        height: 100%;
        background-color: #f95877;
        transform: skew(-8deg);
        border-radius: 4px;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        transition: 0.4s ease-in-out;
        z-index: -1;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        width: 55%;
        height: 100%;
        background-color: #f95877;
        transform: skew(8deg);
        border-radius: 4px;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        transition: 0.4s ease-in-out;
        z-index: -1;
      }
    }
  }

  &--vertical {
    // background-color: red;
    position: relative;
    transition: 0.3s ease-in-out;

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60%;
      background-color: #f95877;
      transform: skewY(-8deg);
      border-radius: 4px;
      transition: 0.4s ease-in-out;
      z-index: -1;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 100%;
      height: 60%;
      background-color: #f95877;
      transform: skewY(8deg);
      border-radius: 4px;
      transition: 0.4s ease-in-out;
      z-index: -1;
    }
  }

  &--primary {
    background-color: #f95877;
  }

  &.disable {
    pointer-events: none;
  }
}

.btn-arrow {
  color: #f95877;
  position: relative;
  padding-right: 15px;

  &::after {
    position: absolute;
    content: "";
    top: 50%;
    right: 0;
    box-sizing: border-box;
    height: 7px;
    width: 7px;
    border-style: solid;
    border-color: #f95877;
    border-width: 0 2px 2px 0;
    transform: translateY(-50%) rotate(-45deg);
    transition: 0.4s ease-in-out;
    z-index: 1;
  }

  &::before {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    width: 0;
    opacity: 0;
    height: 2px;
    background-color: #f95877;
    transition: 0.4s ease-in-out;
    z-index: 1;
  }

  &:hover {
    color: #f95877;

    &::before {
      width: 45%;
      opacity: 1;
    }

    &::after {
      transform: rotate(45deg);
      opacity: 0;
      visibility: hidden;
    }
  }
}

.btn-brd {
  transition: 0s ease-in-out;
}
.ml-3,
.mx-3 {
  margin-left: 1rem !important;
}
// Banner
.home-baner-text {
  &__title {
    margin-bottom: 10px;
  }

  &__head {
    margin-bottom: 15px;

    @media (min-width: 768px) {
      margin-bottom: 25px;
    }
  }

  &__info {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  margin-bottom: 25px;

  @media (min-width: 768px) {
    margin-bottom: 40px;
  }

  @media (min-width: 1200px) {
    margin-bottom: 45px;
  }
}

.home-slider-btn {
  display: flex;
  // justify-content: space-around;
  flex-wrap: wrap;

  &__item {
    margin: 0 18px 18px 0;

    &:last-child {
      margin-right: 0;
    }
  }
}

.home-image-banner {
  @media (min-width: 1200px) {
    transform: translateX(50px);
  }
}
.btn {
  // background-color: #fa5a78;
  color: #ffffff;
  background: none;
  font-size: 14px;
  font-weight: 500;
  min-width: 70px;
  z-index: 2;

  @media (min-width: 768px) {
    font-size: 13px;
  }

  @media (min-width: 992px) {
    padding: 12px 18px;
    font-size: 16px;
    min-width: 100px;
  }

  &--skew {
    position: relative;
    transition: 0.3s ease-in-out;

    &::after {
      position: absolute;
      content: "";
      top: 0;
      left: 0;
      width: 55%;
      height: 100%;
      background-color: #f95877;
      transform: skew(-8deg);
      border-radius: 4px;
      border-top-right-radius: 0 !important;
      border-bottom-right-radius: 0 !important;
      transition: 0.4s ease-in-out;
      z-index: -1;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 55%;
      height: 100%;
      background-color: #f95877;
      transform: skew(8deg);
      border-radius: 4px;
      border-top-left-radius: 0 !important;
      border-bottom-left-radius: 0 !important;
      transition: 0.4s ease-in-out;
      z-index: -1;
    }

    &:hover {
      color: #ffffff;

      &::after {
        transform: skew(8deg);
      }

      &::before {
        transform: skew(-8deg);
      }
    }
  }

  &--skewBrd {
    position: relative;
    transition: 0.3s ease-in-out;
    color: #f95877;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;

    .img-brd {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }

    &:hover {
      color: #ffffff;

      .img-brd {
        display: none;
      }

      &::after {
        position: absolute;
        content: "";
        top: 0;
        left: 0;
        width: 55%;
        height: 100%;
        background-color: #f95877;
        transform: skew(-8deg);
        border-radius: 4px;
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
        transition: 0.4s ease-in-out;
        z-index: -1;
      }

      &::before {
        position: absolute;
        content: "";
        top: 0;
        right: 0;
        width: 55%;
        height: 100%;
        background-color: #f95877;
        transform: skew(8deg);
        border-radius: 4px;
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
        transition: 0.4s ease-in-out;
        z-index: -1;
      }
    }
  }

  &--vertical {
    // background-color: red;
    position: relative;
    transition: 0.3s ease-in-out;

    &::after {
      position: absolute;
      content: "";
      bottom: 0;
      left: 0;
      width: 100%;
      height: 60%;
      background-color: #f95877;
      transform: skewY(-8deg);
      border-radius: 4px;
      transition: 0.4s ease-in-out;
      z-index: -1;
    }

    &::before {
      position: absolute;
      content: "";
      top: 0;
      right: 0;
      width: 100%;
      height: 60%;
      background-color: #f95877;
      transform: skewY(8deg);
      border-radius: 4px;
      transition: 0.4s ease-in-out;
      z-index: -1;
    }
  }

  &--primary {
    background-color: #f95877;
  }

  &.disable {
    pointer-events: none;
  }
}

.btn-arrow {
  color: #f95877;
  position: relative;
  padding-right: 15px;

  &::after {
    position: absolute;
    content: "";
    top: 50%;
    right: 0;
    box-sizing: border-box;
    height: 7px;
    width: 7px;
    border-style: solid;
    border-color: #f95877;
    border-width: 0 2px 2px 0;
    transform: translateY(-50%) rotate(-45deg);
    transition: 0.4s ease-in-out;
    z-index: 1;
  }

  &::before {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    width: 0;
    opacity: 0;
    height: 2px;
    background-color: #f95877;
    transition: 0.4s ease-in-out;
    z-index: 1;
  }

  &:hover {
    color: #f95877;

    &::before {
      width: 45%;
      opacity: 1;
    }

    &::after {
      transform: rotate(45deg);
      opacity: 0;
      visibility: hidden;
    }
  }
}

.btn-brd {
  transition: 0s ease-in-out;
}
.btn--skewBrd {
  color: #f95877 !important;
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  &:hover {
    color: #ffffff !important;
  }
}
