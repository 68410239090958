.btn-primary {
    color: #000 !important;
}

.btn-primary:hover {
    color: #fff !important;
}

.backIcon {
    width: 2%;
}
.card {
    border-radius: 15px;
}
.program-form {
    font-size: 16px;
   font-family: "Poppins" !important;
}
.form-control {
    padding: 0.2rem 0.7rem;
    font-size: 16px;
   font-family: "Poppins" !important;
}
.dropDownElem{
    width: 100%;
    padding: 5px;
    border-color: lightgray;
}
.p-inputswitch.p-inputswitch-checked .p-inputswitch-slider{
    background: #fa5a78 !important;
}

.btn-save{
    color: #fa5a78 !important;
    border-color: #fa5a78 !important;
}

.btn-save:hover{
    background-color: #fa5a78 !important;
    color: #fff;
}

// .btn-cancel{
//     color: red !important;
//     border-color: red;
// }

// .btn-cancel:hover{
//     background-color: red;
//     color: #000 !important;
// }
.header{
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.form-header{
    display: flex;
    align-items: center;
    justify-content: start; 
}
.back-btn{
    font-size: 30px;
    color: #fa5a78;
}
@media (max-width: 480px) {
    .header{
      justify-content: center;
      margin-top: 10px;
      align-items: flex-start;
    }
    .header-search__input {
    width: 150px !important;
    display: inline-flex;
    }
    .searchIcon {
        font-size: 20px;
    }
    .SearchBox {
        font-size: 10px;
    }
    .inputSwitch {
        width: 40% !important;
    }
  }
  .SearchBox {
    outline: 0;
    border: 0;
    width: 100%;
    font-size: 14px;
    color: #242424;
    background-color: #fcfcfc;
    margin-left: 5px;
    color:#808080
}
.header-search__input {
    border: 1px solid #cbcfed;
    background-color: #fcfcfc;
    width: 350px;
}
.inputSwitch{
    width: 15%;
    border:none
}

.error {
    color: red;
    font-size: 12px;
    font-weight: 500;
}